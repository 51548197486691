<template>
  <div class="c-base-layout">
    <div class="ly-account-header">
      <div class="ly-account-header__content g-content">
        <p class="account-header-title">{{ route.meta?.layout?.header === 'my-profile' ? '我的帳戶' : '我的音樂' }}</p>
      </div>
    </div>
    <div class="c-base-layout__content">
      <div class="sidebar-wrap">
        <div class="sidebar g-content">
          <avatar />

          <div class="menu">
            <div class="menu__content">
              <ul class="menu-list">
                <li
                  class="menu-list__item"
                  v-for="item of profileBaseLayoutConfig"
                  :key="item.routeName"
                  @click="toPage(item.routeName)"
                >
                  <p
                    class="menu-list__item__text"
                    :class="{ 'menu-list__item__text--active': route.name === item.routeName }"
                  >
                    {{ item.label }}
                  </p>

                  <img
                    v-show="route.name === item.routeName"
                    class="decorate-img"
                    src="@/assets/icon/highlight_3.png"
                  />
                </li>
              </ul>
            </div>
          </div>

          <sign-out />

          <div class="mobile-menu">
            <div class="mobile-menu__content">
              <ul class="mobile-menu-list">
                <li
                  class="mobile-menu-list__item"
                  v-for="item of profileBaseLayoutConfig"
                  :key="item.routeName"
                  @click="toPage(item.routeName)"
                >
                  <p
                    class="mobile-menu-list__item__text"
                    :class="{ 'mobile-menu-list__item__text--active': route.name === item.routeName }"
                  >
                    {{ item.label }}
                  </p>

                  <img
                    v-show="route.name === item.routeName"
                    class="decorate-img"
                    src="@/assets/icon/highlight_3.png"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="main g-content">
        <p class="curr-page">{{ route.meta.title }}</p>
        <div class="tablet-show">
          <divide-line />
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import profileBaseLayoutConfig from '@/config/profileBaseLayoutConfig';
import DivideLine from '@/components/MyProfile/DivideLine.vue';
import Avatar from '@/components/MyProfile/Avatar.vue';
import SignOut from '@/components/MyProfile/SignOut.vue';
import { useSiteStore } from '@/store/site';
import cusStorage from '@/utils/cusStorage';

export default defineComponent({
  name: 'BaseLayout',
  components: {
    DivideLine,
    Avatar,
    SignOut,
  },
  setup() {
    const siteStore = useSiteStore();
    const route = useRoute();
    const router = useRouter();

    if (!siteStore.isLogin) {
      cusStorage.setItem('loginPageRecord', route.name);
      router.push({ name: 'sign-in' });
    }

    const toPage = (name) => {
      router.push({ name });
    };

    const handleLogout = () => {
      siteStore.handleLogout();
    };

    return {
      route,
      profileBaseLayoutConfig,
      toPage,
      handleLogout,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-base-layout {
  height: 100%;
  margin: 60px 0 90px;

  &__content {
    @include flex(flex-start, flex-start, column);
  }
}

.ly-account-header {
  // padding-top: 80px; // 撐開 header
  height: 100px;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
  background-image: url('~@/assets/profile/banner_profile.png');

  &__content {
    @include flex();
    height: 100%;
  }
}

.account-header-title {
  @include font-style($c-white, 24, bold, 1.2px, 33px);
}

.sidebar-wrap {
  width: 100%;
  box-shadow: 0px 0px 10px rgba($c-black, 0.1);
}

.sidebar {
  width: 100%;
  background-color: $c-white;
  @include flex();
  flex-wrap: wrap;
  @include padding(15px 20px);
}

.main {
  width: 100%;
  @include padding(20px);
}

.menu {
  display: none;
}

.mobile-menu {
  flex: none;
  width: 100%;
  @include flex(center, center);
  margin-top: 20px;

  &__content {
    width: 100%;
  }
}

.mobile-menu-list {
  width: 100%;
  @include flex(space-between);

  &__item {
    position: relative;

    &__text {
      @include font-style($c-assist4, 16, normal, 0.9px, 24px);
      cursor: pointer;
      position: relative;
      z-index: 10;

      &--active {
        color: $c-black;
        font-weight: bold;
      }
    }
  }
}

.curr-page {
  display: none;
}

.decorate-img {
  @include position(br, -4px, 2px);
}

.tablet-show {
  display: none;
}

@media screen and (min-width: $mobile) {
  .mobile-menu-list {
    &__item {
      &__text {
        @include font-size(18);
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .c-base-layout {
    margin: 80px 0 90px;

    &__content {
      padding-top: 65px; // 撐開 header
      @include flex(flex-start, flex-start);
    }
  }

  .ly-account-header {
    // padding-top: 80px; // 撐開 header
    height: 220px;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: cover;
    background-image: url('~@/assets/profile/banner_profile.png');

    &__content {
      @include flex();
      height: 100%;
    }
  }

  .account-header-title {
    @include font-style($c-white, 45, bold, 2.25px, 60px);
  }

  .sidebar-wrap {
    width: auto;
    box-shadow: 0px 0px 0px transparent;
  }

  .sidebar {
    width: 300px;
    background-color: $c-white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba($c-black, 0.1);
    flex-wrap: nowrap;
    @include flex(center, center, column);
  }

  .main {
    margin-left: 70px;
    flex: 1;
  }

  .menu {
    flex: 1;
    width: 100%;

    @include flex(center, center);
    border-top: 1px solid rgba($c-assist8, 0.1);
    border-bottom: 1px solid rgba($c-assist8, 0.1);

    &__content {
      @include padding(36px 0 30px 0);
    }
  }

  .menu-list {
    &__item {
      position: relative;

      & + & {
        margin-top: 24px;
      }

      &__text {
        @include font-style($c-assist4, 18, normal, 0.9px, 24px);
        cursor: pointer;
        position: relative;
        z-index: 10;

        &--active {
          color: $c-black;
          font-weight: bold;
        }
      }
    }
  }

  .mobile-menu {
    display: none;
  }

  .curr-page {
    display: block;
    @include font-style($c-black, 24, bold, 1.2px, 32px);
  }

  .decorate-img {
    @include position(br, -4px, 2px);
  }

  .tablet-show {
    display: block;
  }
}
</style>
