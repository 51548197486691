<template>
  <div class="v-customer-service">
    <base-layout>
      <p class="notice">
        如果您有任何疑問，請聯繫下列專屬客服。或前往
        <router-link :to="{ name: 'front-contact' }"><span class="notice__highlight">聯絡我們</span></router-link>
        留下您的問題，我們會盡快與您聯絡！
      </p>

      <div class="divide-line"></div>

      <div class="customer-service-wrap">
        <ul class="customer-service-box-list">
          <li class="customer-service-box-list__item" v-for="(item, idx) of mockData" :key="item.email">
            <p class="customer-service-label">客服 {{ idx + 1 }}</p>
            <div class="customer-service-box">
              <ul class="customer-service-list">
                <li class="customer-service-list__item">
                  <p class="customer-service-list__item__label">姓名</p>
                  <p class="customer-service-list__item__data">
                    {{ item.name }}
                    <span class="customer-service-list__item__data__gender">({{ item.gender }})</span>
                  </p>
                </li>

                <li class="customer-service-list__item">
                  <p class="customer-service-list__item__label">職稱</p>
                  <p class="customer-service-list__item__data">{{ item.title }}</p>
                </li>
                <li class="customer-service-list__item">
                  <p class="customer-service-list__item__label">Email</p>
                  <p class="customer-service-list__item__data">{{ item.email }}</p>
                </li>
                <li class="customer-service-list__item">
                  <p class="customer-service-list__item__label">電話</p>
                  <p class="customer-service-list__item__data">{{ item.phone }}</p>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </base-layout>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import BaseLayout from '@/components/MyProfile/BaseLayout.vue';

const mockData = [
  {
    name: 'Vickie',
    gender: 'Mr',
    title: '業務經理',
    email: 'Vickie000@gmail.com',
    phone: '0987654321',
  },
  {
    name: 'Kyven',
    gender: 'Mr',
    title: '業務經理',
    email: 'kyven000@gmail.com',
    phone: '0987654321',
  },
  {
    name: 'Yvonne',
    gender: 'Mr',
    title: '業務經理',
    email: 'Yvonne000@gmail.com',
    phone: '0987654321',
  },
];

export default defineComponent({
  name: 'CustomerService',
  components: {
    BaseLayout,
  },
  setup() {
    return {
      mockData,
    };
  },
});
</script>
<style lang="scss" scoped>
.notice {
  @include font-style($c-assist3, 16, normal, 0.32px, 24px);
  &__highlight {
    color: $c-main;
  }
}

.customer-service-wrap {
  margin-top: 40px;
}

.customer-service-box-list {
  &__item {
    & + & {
      margin-top: 28px;
    }
  }
}

.customer-service-label {
  @include font-style($c-assist3, 14, normal, 0.28px, 24px);
}

.customer-service-box {
  background-color: $c-assist2;
  border-radius: 5px;
  margin-top: 8px;
}

.customer-service-list {
  @include flex(flex-start, flex-start, column);
  @include padding(21px 25px);

  &__item {
    width: 100%;
      @include flex();

      &+& {
        margin-top: 20px;
      }

    &__label {
      flex: none;
      width: 45px;
      @include font-style($c-assist9, 16, normal, 0.32px, 24px);
    }

    &__data {
      margin-left: 20px;
      flex: 1;
      @include font-style($c-black, 16, normal, 0.32px, 24px);

      &__gender {
        color: $c-assist4;
      }
    }
  }
}

.divide-line {
  width: 100%;
  height: 1px;
  background-color: rgba($c-assist3, 0.5);
  margin: 30px 0 30px;
}

@media screen and (min-width: $tablet) {
  .notice {
    @include font-style($c-assist3, 16, normal, 0.32px, 24px);
    &__highlight {
      color: $c-main;
    }
  }

  .customer-service-wrap {
    margin-top: 40px;
  }

  .customer-service-box-list {
    &__item {
      & + & {
        margin-top: 28px;
      }
    }
  }

  .customer-service-label {
    @include font-style($c-assist3, 14, normal, 0.28px, 24px);
  }

  .customer-service-box {
    background-color: $c-assist2;
    border-radius: 5px;
    margin-top: 8px;
  }

  .customer-service-list {
    @include flex(space-between);
    @include padding(21px 25px);

    &__item {
      @include flex(flex-start, flex-start, column);

      &+& {
        margin-top: 0px;
      }

      &__label {
        @include font-style($c-assist9, 16, normal, 0.32px, 24px);
      }

      &__data {
        margin-top: 18px;
        margin-left: 0px;
        @include font-style($c-black, 16, normal, 0.32px, 24px);

        &__gender {
          color: $c-assist4;
        }
      }
    }
  }

  .divide-line {
    width: 100%;
    height: 1px;
    background-color: rgba($c-assist3, 0.5);
    margin: 30px 0 30px;
  }
}
</style>
