export default [
  {
    label: '帳戶資料',
    routeName: 'my-profile-personal-details',

  },
  {
    label: '更改密碼',
    routeName: 'my-profile-change-password',
  },
  {
    label: '偏好設定',
    routeName: 'my-profile-my-prefer',
  },
  {
    label: '音韶客服',
    routeName: 'my-profile-customer-service',
  },
];
